import React, { useMemo, useState } from "react";
import { createRoot } from "react-dom/client";

import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
} from "shared/providers/apollo/apollo.package";
import apolloCache from "./common/apollo/cache";
import apolloSplitLink from "./common/apollo/split-link";
import apolloErrorLink from "./common/apollo/error-link";

import RouterProvider from "shared/providers/router/router.provider";
import StylesProvider from "shared/providers/styles.provider";
import DNDProvider from "shared/providers/dnd/dnd.provider";
import DayjsProvider from "shared/providers/dayjs/dajys.provider";

import { HelmetProvider } from "react-helmet-async";

import App from "./components/app/app.component";

import reportWebVitals from "./reportWebVitals";

const RootComponent = () => {
  const [isMaintenance, setIsMaintenance] = useState(false);

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        link: ApolloLink.from([
          apolloErrorLink(setIsMaintenance),
          apolloSplitLink,
        ]),
        cache: apolloCache,
      }),
    []
  );

  return (
    <React.StrictMode>
      <StylesProvider>
        <HelmetProvider>
          <ApolloProvider client={apolloClient}>
            <DNDProvider>
              <RouterProvider>
                <DayjsProvider>
                  <App isMaintenance={isMaintenance} />
                </DayjsProvider>
              </RouterProvider>
            </DNDProvider>
          </ApolloProvider>
        </HelmetProvider>
      </StylesProvider>
    </React.StrictMode>
  );
};

const root = createRoot(document.getElementById("app")!);

root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
